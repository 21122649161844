:root {
  --darkbg:#251D29;
  --darkt: #FFD1F7;
  --lightbg: #fff;
  --lightt: #D43370;
  
  --toggleHeight: 16em;
  --toggleWidth: 30em;
  --toggleBtnRadius: 10em;

  --bgColor--night: #423966;
  --toggleBtn-bgColor--night: var(--bgColor--night);
  --mooncolor: #D9FBFF;
  --bgColor--day: #9ee3fb;
  --toggleBtn-bgColor--day: var(--bgColor--day);
}

.light{
  background: var(--lightbg);
  color: var(--lightt);
}
.tdnn {
  /*change size of toggle with font-size*/
  font-size: 1.6px;
  margin-right: 8px;
   position: relative;
    height: var(--toggleHeight);
    width: var(--toggleWidth);
    border-radius: var(--toggleHeight);
  background: var(--bgColor--night);
  border: 0.5px solid #0000000a;
}
.day{
  background: #0088ff;
}
.moon {
  position: absolute;
  display: block;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
  
  top: 3em;
  left: 3em;
  transform: rotate(-75deg);
  width: var(--toggleBtnRadius);
  height: var(--toggleBtnRadius);
  background: var(--bgColor--night);
  box-shadow: 
    3em 2.5em 0 0em var(--mooncolor) inset,
    rgba(255, 255, 255, 0.1) 0em -7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 3em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 2em 13em 0 -4em,
    rgba(255, 255, 255, 0.1) 6em 2em 0 -4.1em,
    rgba(255, 255, 255, 0.1) 8em 8em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 6em 13em 0 -4.5em,
    rgba(255, 255, 255, 0.1) -4em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) -1em 10em 0 -4.5em;
}
.sun {
  top: 4.5em;
  left: 18em;
  transform: rotate(0deg);
  width: 7em;
  height: 7em;
  background: #FDB813;
  box-shadow: 3em 3em 0 5em #FDB813 inset,
    0 -5em 0 -2.7em #FDB813,
    3.5em -3.5em 0 -3em #FDB813,
    5em 0 0 -2.7em #FDB813,
    3.5em 3.5em 0 -3em #FDB813,
    0 5em 0 -2.7em #FDB813,
    -3.5em 3.5em 0 -3em #FDB813,
    -5em 0 0 -2.7em #FDB813,
    -3.5em -3.5em 0 -3em #FDB813;
}